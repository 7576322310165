import { createSelector } from 'reselect'

import permissions, {
  any,
  hasDashboardOrgCreate,
  hasDashboardOrgEdit,
  hasSiteCreate,
  hasSiteEdit,
  hasSiteView,
  hasUserCreate,
  hasUserEdit,
  hasUserView,
  hasDeviceLogging
} from 'Selectors/PermissionsSelector'

export const isAnomaliesSubscribedFeature = ({ user }) => user.subscription?.features?.includes('devices.anomalies')
export const isLogAnalyzerSubscribedFeature = ({ user }) => user.subscription?.features?.includes('tools.loganalyzer')

export const isSiteViewer = ({ permissions }) => !!permissions?.allPermissions?.['site:read']
export const isSiteCreate = ({ permissions }) => !!permissions?.allPermissions?.['site:create']
export const isSiteEdit = ({ permissions }) => !!permissions?.allPermissions?.['site:edit']

export const isAutomationViewer = ({ permissions }) => !!permissions?.allPermissions?.['rules:read']

export const isDashboardCreate = ({ permissions }) => !!permissions?.allPermissions?.['dashboard:create']
export const isDashboardEdit = ({ permissions }) => !!permissions?.allPermissions?.['dashboard:edit']

export const isViewScheduleAccess = ({ permissions }) => !!permissions?.allPermissions?.['device:update:view']
export const isDeviceView = ({ permissions }) => !!permissions?.allPermissions?.['device:read']
export const isDashboardView = ({ permissions }) => !!permissions?.allPermissions?.['dashboard:read']
export const isDeviceAssignOnlyView = ({ permissions }) => !!permissions?.allPermissions?.['asset:viewonlyassigned']

export const isCounterAppUser = ({ permissions }) => !!permissions?.allPermissions?.['counter:app_usage']

export const isWorkflowInsights = ({ user }) => user.subscription?.features?.includes('workflow:insights')

export const all = (...permissions) => permissions.reduce((isPermission, permission) => isPermission && permission, true)

export const menuValueCollapsable = state => state.menuOptions

export const hasOrganization = ({ user }) => user.getIn(['selectedOrganizationId'], '')

export const accessToken = state => state.login.accessToken

export const isSiteAdmin = createSelector(
  hasSiteEdit,
  hasSiteCreate,
  any
)

export const isUserAdmin = createSelector(
  hasUserEdit,
  hasUserCreate,
  any
)

export const isDashboardAdmin = createSelector(
  hasDashboardOrgEdit,
  hasDashboardOrgCreate,
  any
)

export const hasAdminMenuAccess = createSelector(
  isUserAdmin,
  isSiteAdmin,
  isDashboardAdmin,
  hasSiteView,
  hasUserView,
  any
)

export const hasDeviceAdminMenuAccess =  createSelector(
  hasDeviceLogging,
  isAutomationViewer,
  any
)

export const isAutomationSubscribedFeature = ({ user }) => user.subscription?.features?.includes('device.administration.rulesengine');

export const hasPackageCarSubscribedFeature = ({ user }) =>
{ 
  return user?.subscription?.features?.includes('packagecar');
}

export default state => {
  return {
    ...permissions(state),
    hasAdminMenuAccess: hasAdminMenuAccess(state),
    isUserAdmin: isUserAdmin(state),
    isSiteAdmin: isSiteAdmin(state),
    isDashboardAdmin: isDashboardAdmin(state),
    menuValueCollapsable: menuValueCollapsable(state),
    accessToken: accessToken(state),
    hasOrganization: hasOrganization(state),
	  hasDeviceAdminMenuAccess: hasDeviceAdminMenuAccess(state),
    isAutomationSubscribedFeature: isAutomationSubscribedFeature(state),
    isAnomaliesSubscribedFeature: isAnomaliesSubscribedFeature(state),
    isLogAnalyzerSubscribedFeature: isLogAnalyzerSubscribedFeature(state),
    hasPackageCarSubscribedFeature: hasPackageCarSubscribedFeature(state),
    hasWorkflowInsights: isWorkflowInsights(state)
  }
}
